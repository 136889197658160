import { myMixin } from '../../../mixins';
import { mapActions, mapState } from 'vuex'

export default{
  mixins: [myMixin],
  data: () => ({
    collections: [],
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    meta: {},
    itemDelete: {},
    model: {},
    payloadFilter: null,
    dialogFilter: false,
    show: false,
    showForm: false,
    searchUserCreator: null,
    restrict: false,
    text: 'Carregando dados...',
    loadingProgress: false,
    showApproval: false,
    textDelete: '',
    deleting: false,
    headers: [
        { text: 'ID', value: 'id'},
        { text: 'Campus/Departamento', value: 'campus', sortable: false},
        { text: 'Professor', value: 'teachers', sortable: false},
        { text: 'Atividade', value: 'activity', sortable: false},
        { text: 'Tipo', value: 'type', sortable: false},
        { text: 'Cadastro', value: 'register', sortable: false},
        { text: 'Status', value: 'status', align: 'center', hidden: true, sortable: false},
        { text: 'Ações', value: 'actions'},

    ],
    filtersTags: [0, 1, 2, 3, 4, 5],
    icons: ['mdi-check', 'mdi-close-circle-outline'],
    summary: {pending: 0, authorizedN1: 0, authorizedN2: 0, disapproved: 0, done: 0, removed: 0 },
    tags: [
        { id: 0, name: 'Pendente',          value:'pending',        color: 'amber',     icon: 'mdi-checkbox-blank-circle-outline',  'active': 1},
        { id: 1, name: 'Autorizado-N1',     value:'authorizedN1',   color: 'success',   icon: 'mdi-thumb-up-outline',               'active': 1 },
        { id: 2, name: 'Autorizado-N2',     value:'authorizedN2',   color: 'success',   icon: 'mdi-thumb-up-outline',               'active': 1 },
        { id: 3, name: 'Reprovado',         value:'disapproved',    color: 'error',     icon: 'mdi-thumb-down-outline',             'active': 1},
        { id: 4, name: 'Realizado',         value:'done',           color: 'primary',   icon: 'mdi-check-all',                      'active': 0},
        { id: 5, name: 'Removido',          value:'removed',        color: 'error',     icon: 'mdi-account-remove',                 'active': 1},
        
      ],
    }),
    
    watch: {
      params(params_new, params_old){
        if(params_new != params_old) {
            this.getData()
        }
      },
       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir o item " + item_new.activity.name + "?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {};
          }
      },
    },
    computed: {
        ...mapState('auth', ['acesso', 'user']),
        
        itemsFiltered(){
            
            var items = this.collections.filter(i => this.filtersTags.includes(i.activity_item_status_id - 1));
            if(this.searchUserCreator){
                items = items.filter(i => (i.userCreator && i.userCreator.name.toString().toLocaleUpperCase().indexOf(this.searchUserCreator.toString().toLocaleUpperCase()) !== -1));
            }

            return items;
        }

    },
    created() {
      this.restrict = this.$route.name == 'activity-items-restrict';
      this.showApproval = this.acesso.instituicao.enable_activity_approval_flow;
      
      if(this.headers && this.headers[5]){
        this.headers[5].hidden = !this.showApproval;
      }

      
    },
    methods: {
      ...mapActions('activityItem', ['ActionFindActivityItems', 'ActionDeleteActivityItem', 'ActionIndexActivityItemTeachers']),
        
      downloadCompleted(error, message){

        this.error = false;
        this.message = '';
        
        if(error){
            
            this.message = '';

            this.$nextTick(() => {
                this.message = message;
                this.error = error;
            });
           
        }


      },
      
      getData(){
      
        this.error = false;
        this.message = '';
        this.loadingProgress = true;
        this.showForm = false;

        this.params.with = 'attributions,campus,departament,courses,activity.category.departament,valueType,periodicityType,status,userApproval,userCreator,timeTables.dayOfWeek,teachers.teacher';
        this.params.filterByDepartament = true;

        delete this.params.find_columns;
        delete this.params.filter_relations;

        if(this.payloadFilter){
            this.params.find_columns        = this.payloadFilter.find_columns;
            this.params.filter_relations    = this.payloadFilter.filter_relations;
        }

        this.ActionFindActivityItems(this.params)
            .then((res) => {
                this.collections    = res.data;
                this.setSummary(res.data);
                this.meta           = res.meta;
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
            })
    },

    setSummary(items){

        this.summary = [0,0,0,0,0];
        
        items.forEach(item => {
            this.summary[item.activity_item_status_id - 1]++;
        });

    },

    deleteItem()
        {
            this.deleting = true;
            this.message = '';

            this.ActionDeleteActivityItem({id: this.itemDelete.id})
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                  this.message = error.message;
                  this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                });
        },

  },

}
